export const ProjectStatus = {
  DEFINITE_QUALIFICATION: 0,
  WAITING_FOR_QUALIFICATION: 1,
  WAITING_FOR_APPROVAL: 2,
  WAITING_FOR_CORRECTION: 3,
  WAITING_FOR_PARTIAL_REPORT_1: 4,
  AWAITING_PARTIAL_REPORT_2: 5,
  WAITING_FOR_FINAL_REPORT: 6,
  CANCELED: 7,
  SUSPENDED: 8,
}

export const ProjectStatusClassColors = {
  [ProjectStatus.DEFINITE_QUALIFICATION]: 'teal darken-1',
  [ProjectStatus.WAITING_FOR_QUALIFICATION]: 'yellow darken-1',
  [ProjectStatus.WAITING_FOR_APPROVAL]: 'yellow darken-1',
  [ProjectStatus.WAITING_FOR_CORRECTION]: 'yellow darken-1',
  [ProjectStatus.WAITING_FOR_PARTIAL_REPORT_1]: 'yellow darken-1',
  [ProjectStatus.AWAITING_PARTIAL_REPORT_2]: 'yellow darken-1',
  [ProjectStatus.WAITING_FOR_FINAL_REPORT]: 'yellow darken-1',
  [ProjectStatus.CANCELED]: 'red darken-1',
  [ProjectStatus.SUSPENDED]: 'red darken-1',
}

export const ProjectStatusText = {
  [ProjectStatus.DEFINITE_QUALIFICATION]: 'HABILITAÇÃO DEFINITIVA',
  [ProjectStatus.WAITING_FOR_QUALIFICATION]: 'AGUARDANDO HABILITAÇÃO',
  [ProjectStatus.WAITING_FOR_APPROVAL]: 'AGUARDANDO APROVAÇÃO',
  [ProjectStatus.WAITING_FOR_CORRECTION]: 'AGUARDANDO CORREÇÃO',
  [ProjectStatus.WAITING_FOR_PARTIAL_REPORT_1]:
    'AGUARDANDO RELATÓRIO PARCIAL 1',
  [ProjectStatus.AWAITING_PARTIAL_REPORT_2]: 'AGUARDANDO RELATÓRIO PARCIAL 2',
  [ProjectStatus.WAITING_FOR_FINAL_REPORT]: 'AGUARDANDO RELATÓRIO FINAL',
  [ProjectStatus.CANCELED]: 'CANCELADO',
  [ProjectStatus.SUSPENDED]: 'SUSPENSO',
}
