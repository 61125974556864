import api from '../../../api/api'

export default {
  create: async form => {
    const result = api.save.createProjectPlanning(form)

    return result
  },

  show: async id => {
    const result = api.get.projectFarm(id)

    return result
  },

  searchByUser: async id => {
    const result = api.get.searchSchedules(id)

    return result
  },

  patch: async ({ id, form }) => {
    const result = api.patch.farmProject(id, form)
    return result
  },

  inactivate: async id => {
    const result = api.delete.inactivateFarmProject(id)

    return result
  },

  dashboardVisit: async id => {
    const result = api.get.dashboardVisit(id)

    return result
  },

  dashboardControl: async id => {
    const result = api.get.dashboardControl(id)

    return result
  },
}
