<template>
  <v-dialog v-model="dialog" width="800px">
    <v-card>
      <base-loading v-if="loading.loading" />
      <div v-else>
        <v-card>
          <v-card-title class="d-flex text-h5 primary lighten-1">
            <span
              v-if="!register"
              slot="header"
              class="card-title text-center white--text"
            >
              Editar fazenda do projeto
            </span>
            <span
              v-else
              slot="header"
              class="card-title text-center white--text"
            >
              Cadastrar fazenda do projeto
            </span>
          </v-card-title>

          <div class="clearfix" />
          <div>
            <v-form
              id="form"
              ref="form"
              v-model="valid"
              enctype="multipart/form-data"
              class="pa-3"
              lazy-validation
              @submit.prevent="createOrUpdate"
            >
              <v-card>
                <v-list-item-content>
                  <v-card-title class="headline mx-auto">
                    Dados da associação
                  </v-card-title>
                  <v-row v-if="!loading.loading" class="pa-3">
                    <v-col cols="5">
                      <v-text-field
                        v-model="form.fazenda.pessoajuridica.NomeFantasia"
                        label="Fazenda *"
                        :rules="rules.requiredRules"
                        :disabled="true"
                        required
                      />
                    </v-col>

                    <v-col cols="3">
                      <v-menu
                        v-model="date.startDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :value="moment(form.startDate).format('DD/MM/YYYY')"
                            label="Data da criação*"
                            prepend-icon="mdi-calendar"
                            color="teal"
                            readonly
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="form.startDate"
                          style="margin: 0px"
                          color="teal"
                          locale="pt-br"
                          @input="date.startDate = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        v-model="date.endDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :value="
                              form.endDate
                                ? moment(form.endDate).format('DD/MM/YYYY')
                                : ''
                            "
                            label="Data de término"
                            prepend-icon="mdi-calendar"
                            color="teal"
                            readonly
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="form.endDate"
                          style="margin: 0px"
                          color="teal"
                          locale="pt-br"
                          @input="date.endDate = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col cols="5">
                      <v-autocomplete
                        v-model="form.substitute"
                        label="Fazenda subistituta"
                        name="fazendas"
                        :items="list.fazendas"
                        :loading="loading.fazendas"
                        :disabled="loading.fazendas"
                        item-text="pessoajuridica.NomeFantasia"
                        item-value="CodFazenda"
                        clearable
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        v-model="form.substituteReason"
                        clearable
                        label="Motivo da saída"
                        variant="outlined"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-list-item-content>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    type="submit"
                    :disabled="loading.save"
                    :loading="loading.save"
                    :color="!$route.query.id ? 'success' : 'warning'"
                    form="form"
                  >
                    {{ !$route.query.id ? 'Salvar' : 'Atualizar' }}
                  </v-btn>

                  <v-btn
                    :disabled="loading.save"
                    color="error"
                    class="ml-3"
                    @click="dialog = false"
                  >
                    Cancelar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </div>
        </v-card>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import projectFarmService from '../../../services/api/farmProject'
import farmsService from '../../../services/api/farms'
import {
  ProjectStatus,
  ProjectStatusClassColors,
  ProjectStatusText,
} from '../../../utils/enums/ProjectStatus'
export default {
  components: {},

  emits: ['update'],

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: null,
    },

    fazendasProject: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      ProjectStatus,
      ProjectStatusClassColors,
      ProjectStatusText,
      loading: {
        loading: false,
        save: false,
        fazendas: false,
      },

      form: {
        startDate: null,
        endDate: null,
        substitute: null,
        fazenda: null,
        substituteReason: null,
      },

      rules: {
        requiredRules: [v => !!v || '* Obrigatório'],
      },

      list: {
        fazendas: [],
      },

      agroindustry: null,

      date: {
        startDate: false,
        endDate: false,
      },

      valid: false,
      register: 0,

      dialog: false,
    }
  },

  watch: {
    value: {
      handler() {
        this.dialog = this.value
      },
      immediate: true,
    },

    dialog: {
      handler() {
        this.$emit('input', this.dialog)
      },
      immediate: true,
    },
  },

  mounted() {},

  async created() {
    this.register = this.$route.query.agroindustry === 0 ? 1 : 0
    this.agroindustry = {
      ...this.$route.query,
    }

    await this.init()
  },

  methods: {
    async init() {
      this.loading.loading = true

      await this.index()
      await this.loadFarms()

      this.loading.loading = false
    },

    async createOrUpdate() {
      this.loading.save = true

      try {
        const result = await projectFarmService.patch({
          id: this.id,
          form: {
            ...this.form,
            startDate: this.moment(this.form.startDate).format('YYYY-MM-DD'),
            endDate: this.form.endDate
              ? this.moment(this.form.endDate).format('YYYY-MM-DD')
              : null,
          },
        })

        if (!result.success) {
          throw new Error(result.message)
        }

        this.Toast().fire({
          icon: 'success',
          title: result.message,
        })

        const fazendasProject = this.fazendasProject.map(item => {
          if (item.id === this.id) {
            return {
              ...result.data,
            }
          }
          return item
        })

        this.$emit('update', fazendasProject)

        this.dialog = false
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao salvar projeto: ' + error.message,
        })
      } finally {
        this.loading.save = false
      }
    },

    async index() {
      try {
        const result = await projectFarmService.show(this.id)

        if (!result.success) {
          throw new Error(result.message)
        }

        this.form = {
          ...result.data,
          startDate: result.data.startDate
            ? this.moment(result.data.startDate).format('YYYY-MM-DD')
            : null,
          endDate: result.data.endDate
            ? this.moment(result.data.endDate).format('YYYY-MM-DD')
            : '',
          status: {
            text: result.data.status === 0 ? 'Ativo' : 'Inativo',
            value: result.data.status,
          },
          substitute: result.data?.substituto?.fazendaId,
          substituteReason: result.data.substituteReason,
        }
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar projeto: ' + error.message,
        })
      }
    },

    async loadFarms() {
      this.loading.fazendas = true
      const data = await farmsService.list()
      this.list.fazendas = data
      this.loading.fazendas = false
    },
  },
}
</script>
